import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Main/index.tsx";
import Provider, { YellowText, PaddedBox } from '../../../components/Developers/Provider';
import CentreAlign from '../../../components/CentreAlign.tsx';
import LinkTiles, { Tile } from '../../../components/LinkTiles';
import { Box } from '@material-ui/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Provider mdxType="Provider">
      <Box textAlign="center" padding={7} mdxType="Box">
        <h4 {...{
          "id": "we-provide-the-resources-to-get-you-in-operation-with-minimal-integration",
          "style": {
            "position": "relative"
          }
        }}>{`We provide the resources to get you in operation with minimal `}<strong parentName="h4">{`integration`}</strong><a parentName="h4" {...{
            "href": "#we-provide-the-resources-to-get-you-in-operation-with-minimal-integration",
            "aria-label": "we provide the resources to get you in operation with minimal integration permalink",
            "className": "anchor after"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a></h4>
      </Box>
      <Box textAlign="left" padding={5} mdxType="Box">
        <p>{`We have QuickStart, iFrame and API docs, along with errors and lifecycles documentation to help get you started. Can't see what
you need? Reach out and we can discuss your use case and provide the easiest path to your objectives. To get access to a
demo account, use the Demo button located at the top right corner of the page.`}</p>
        <ul>
          <li parentName="ul">{`The QuickStart guide covers basic concepts and philosophies and shows the quickest path to testing and MVP.`}</li>
          <li parentName="ul">{`iFrame Integration guide shows the many options and choices for using the iFrame; from colours to deep linking, everything is covered here.`}</li>
          <li parentName="ul">{`Response Codes gives you an index of all the different lifecycle events so you can handle every possible use case.`}</li>
        </ul>
      </Box>
    </Provider>
    <Box padding={4} mdxType="Box">
  <LinkTiles filterFn={page => /^\/docs\//.test(page.frontmatter.slug) && (!page.frontmatter?.hidden || !/legacy/.test(page.frontmatter.slug))} mdxType="LinkTiles" />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      